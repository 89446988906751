<template>
  <div class="container position-relative">
    <!-- begin::No module linked -->
    <no-module-linked-alert v-if="equipment.isDeviceLinked() === false" class="col-12" />
    <!-- end::No module linked -->

    <template v-else>
      <!-- begin::Disclaimer -->
      <div class="row border-bottom">
        <div class="alert alert-custom alert-light-info col-12">
          <div class="alert-icon">
            <i>
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </i>
          </div>
          <div class="alert-text">
            The use of online radio may be subject to licensing fees which could be charged by local governments or agencies, depending on the country you are located in.<br />
            VDL Hapro and IT partners hereby expressly decline any responsibility for such fees.
          </div>
        </div>
      </div>
      <!-- end::Disclaimer -->

      <!-- begin::Legacy device alert -->
      <template v-if="equipment.isDeviceLinked() && device.isLegacyDevice()">
        <div class="loading-container loading-overlap m-n5 h-auto">
          <div class="loading-backdrop rounded opacity-20" />
          <div class="loading-block">
            <div class="alert alert-custom alert-light-info ml-auto mr-auto w-100 p-3 d-block">
              <div
                class="alert-text"
                v-html="$t('sunbeds.legacy_device_warning')"
              />
            </div>
          </div>
        </div>
      </template>
      <!-- end::Legacy device alert -->

      <!-- begin::Update alert -->
      <template v-if="isFeatureAvailable() === false">
        <div class="loading-container loading-overlap m-n5 h-auto">
          <div class="loading-backdrop rounded opacity-20" />
          <div class="loading-block">
            <div class="alert alert-custom alert-light-info ml-auto mr-auto w-100 p-3 d-block">
              <div class="alert-text">
                Your MyLuxura Modules should be updated in order to use online radio.<br />
                Please wait for your Myluxura Module to finish updating or contact your dealer if this message persists.
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- end::Update alert -->

      <!-- begin::Internet radio information -->
      <div class="row border-bottom pb-3 mt-3 position-relative">
        <!-- begin::Software version labels -->
        <div class="col-12 col-md-8 offset-md-2">
          <div class="row text-center">
            <!-- begin::Radio status -->
            <div class="col form-group">
              <label class="text-upper">Radio status</label>
              <div>
                <span
                  :class="[
                    'label w-150px label-inline label-xl label-rounded p-5',
                    isInternetRadioAvailable() === true
                      ? isInternetRadioEnabled() === true
                        ? 'label-light-success'
                        : 'label-light-info'
                      : 'label-light-danger'
                  ]"
                >
                  {{
                    isInternetRadioAvailable() === true
                      ? isInternetRadioEnabled() === true
                        ? 'Enabled'
                        : 'Disabled'
                      : 'Unavailable'
                  }}
                </span>
              </div>
            </div>
            <!-- end::Radio status -->

            <!-- begin::Radio station -->
            <div class="col form-group">
              <label class="text-upper">Radio station</label>
              <div>
                <span
                  class="label w-150px label-inline label-xl label-rounded p-5 label-light-info"
                >
                  {{
                    isInternetRadioAvailable() === true && isInternetRadioEnabled() === true && station
                      ? station.name
                      : '-'
                  }}
                </span>
              </div>
            </div>
            <!-- end::Radio station -->
          </div>
        </div>
      </div>
      <!-- end::Internet radio information -->

      <div v-if="equipment.isDeviceLinked() && isInternetRadioAvailable() && $auth.hasPermission('equipment.controller.reset')" class="row mt-3 border-bottom pb-3">
        <div class="col-12 col-md">
          <h5 class="overflow-ellipsis">Online radio</h5>
          <span>
            When online radio is enabled, it will be available on audio channel 03 on your sunbed.
          </span>
        </div>

        <div class="col mt-3 my-md-auto text-right">
          <!-- begin::Stop playing -->
          <button
            v-if="isInternetRadioEnabled()"
            type="button"
            class="btn btn-warning w-150px"
            :disabled="form.isLoading || device.isLegacyDevice() || equipment.state === 'offline'"

            @click.prevent="onStopRadioStation()"
          >
            <i class="svg-icon">
              <font-awesome-icon :icon="['fas', 'stop']" />
            </i>
            Disable
          </button>
          <!-- end::Stop playing -->

          <!-- begin::Start playing -->
          <span v-else class="label label-inline label-xl p-5 label-light-info">
            Enable online radio by selecting a station below
          </span>
          <!-- end::Start playing -->
        </div>
      </div>

      <!-- begin::Internet radio list -->
      <div v-if="isInternetRadioAvailable() === true" class="row mt-3">
        <div class="col">
          <!-- begin::Datatable actions -->
          <div class="mb-7">
            <div class="row align-items-center">
              <div class="col-12 col-lg">
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search..."
                    @keyup.enter="$refs.datatable.search"
                    @keyup="searchDebounced"
                  >
                  <span>
                    <i>
                      <font-awesome-icon :icon="['fas', 'search']" />
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- end::Datatable actions -->

          <!-- begin::Datatable -->
          <datatable
            ref="datatable"
            :url="datatable.url"
            :fields="datatable.fields"
            :filters="datatable.filters"
            :search-fields="datatable.searchFields"
            :on-pagination-data="onPaginationData"
            :sort-order="datatable.sortOrder"

            :transform="transform"
          >
            <!-- begin::Action -->
            <div slot="action" slot-scope="props">
              <!-- begin::Stop button -->
              <button
                v-if="isInternetRadioEnabled() === true && device.settings.radio.player.path === props.rowData.stream_url"
                :key="`internet_radio_station.${props.rowData._meta.guid}.action:play`"
                type="button"
                class="btn btn-light-warning w-100px btn-sm btn-block ml-auto"

                :disabled="equipment.state === 'offline'"

                @click.prevent="onStopRadioStation(props.rowData)"
              >
                <i class="svg-icon">
                  <font-awesome-icon :icon="['fas', 'stop']" />
                </i>
                Stop
              </button>
              <!-- end::Stop button -->

              <!-- begin::Play button -->
              <button
                v-else
                :key="`internet_radio_station.${props.rowData._meta.guid}.action:play`"
                type="button"
                class="btn btn-light-success w-100px btn-sm btn-block ml-auto"

                :disabled="equipment.state === 'offline'"

                @click.prevent="onPlayRadioStation(props.rowData)"
              >
                <i class="svg-icon">
                  <font-awesome-icon :icon="['fas', 'play']" />
                </i>
                Play
              </button>
              <!-- end::Play button -->
            </div>
            <!-- end::Action -->
          </datatable>
          <!-- end::Datatable -->

          <!-- begin::Datatable footer -->
          <div class="datatable datatable-default">
            <div class="datatable-pager datatable-paging-loaded">
              <datatable-pagination
                ref="pagination"
                @vuetable-pagination:change-page="onChangePage"
              />

              <datatable-pagination-info
                ref="pagination.info"
                @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
              />
            </div>
          </div>
          <!-- end::Datatable footer -->
        </div>
      </div>
      <!-- end::Internet radio list -->
    </template>
  </div>
</template>

<script>
import semver from 'semver';
import { DatatableMixin } from '@vedicium/metronic-vue';
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

import Equipment from '@/libs/classes/equipment';
import InternetRadioStation from '@/libs/classes/internet_radio_station';

import noModuleLinkedAlert from '@/components/pages/sunbeds/view/components/no-module-linked.alert.vue';

export default {
  mixins: [equipmentMixin, DatatableMixin],
  components: {
    noModuleLinkedAlert,
  },
  data () {
    return {
      station: null,

      form: {
        isLoading: false,
      },

      datatable: {
        url: InternetRadioStation.uri,

        fields: [{
          name: 'name',
          title: 'Station',
          sortField: 'name',
          titleClass: 'w-150px',
          dataClass: 'w-150px',
        }, {
          name: 'genres',
          title: 'Genre',
          sortField: 'genres',
          titleClass: 'w-150px',
          dataClass: 'w-150px',
          formatter (value) {
            return value.join(', ');
          },
        }, {
          name: 'region',
          title: 'Region',
          sortField: 'region',
          titleClass: 'w-200px',
          dataClass: 'w-200px',
        }, {
          name: 'action',
          title: '',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }],
        searchFields: ['name', 'genres', 'region'],

        sortOrder: [{
          field: 'name',
          direction: 'asc',
        }],
      },
    };
  },

  async mounted () {
    if (this.isInternetRadioAvailable() && this.isInternetRadioEnabled()) {
      try {
        const results = await this.$ws.get(InternetRadioStation.uri, {
          query: {
            'filter[stream_url]': this.device.settings.radio.player.path,
          },
        });

        if (results.length > 0) {
          this.$set(this, 'station', new InternetRadioStation().merge(results[0]));
        }
      } catch (e) {
        console.error(e);
      }
    }
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new InternetRadioStation().merge(row));
      }
      return response;
    },

    isFeatureAvailable () {
      return semver.satisfies(this.device.getSemanticSoftwareVersion(), '>=0.9.0');
    },

    isInternetRadioAvailable () {
      return this.device.peripherals.sound.available === true;
    },

    isInternetRadioEnabled () {
      return this.device.settings.radio.player.enabled === true;
    },

    async onPlayRadioStation (station = null) {
      if (!station || station instanceof InternetRadioStation === false) {
        return;
      }

      this.$set(this.form, 'isLoading', true);
      this.$refs.datatable.setLoading(true);
      try {
        await this.$ws.put(`${Equipment.uri}/${this.equipment._meta.guid}/device/sound/play`, {
          body: {
            path: station.stream_url,
          },
        });

        this.$set(this.device.settings.radio.player, 'enabled', true);
        this.$set(this.device.settings.radio.player, 'path', station.stream_url);
        this.$set(this, 'station', station);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$set(this.form, 'isLoading', false);
        this.$refs.datatable.setLoading(false);
      }
    },

    async onStopRadioStation () {
      this.$set(this.form, 'isLoading', true);
      this.$refs.datatable.setLoading(true);
      try {
        await this.$ws.put(`${Equipment.uri}/${this.equipment._meta.guid}/device/sound/stop`);

        this.$set(this.device.settings.radio.player, 'enabled', false);
        this.$set(this, 'station', null);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
      } finally {
        this.$set(this.form, 'isLoading', false);
        this.$refs.datatable.setLoading(false);
      }
    },
  },
};
</script>
